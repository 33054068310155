import React, { Component } from 'react';
import { msalConfig } from './MsalConfig';
import { UserAgentApplication } from 'msal';
import { getUserDetails } from '../graph/GraphService'

export const msalAuth = new UserAgentApplication({
    auth: msalConfig
});

export function withAuth(HocComponent) {
    return class extends Component {
        constructor(props) {
            super(props);

            this.state = {
                isAuthenticated: false,
                user: {},
                renewIframe: false,
                hasError: false,
                errorMessage: null,
                nokey: false
            };
        }

        async getMail() {

            if (this.state.panoptoUserMail === undefined) {
              
                const accessTokenRequest = {
                    scopes: ["user.read"]
                }
                var accessToken = null;
                try {
                    accessToken = await msalAuth.acquireTokenSilent(accessTokenRequest);
                }
                catch (error) {
                    //console.log("AquireTokenSilent failure");
                    accessToken = await msalAuth.acquireTokenPopup(accessTokenRequest);
                }
                if (accessToken) {
                    var user2 = await getUserDetails(accessToken);
                    this.setState({
                        panoptoUserMail: user2.mail,
                        givenName: user2.givenName
                    });
                    return this.state;
                }

            } else {
                return this.state.panoptoUserMail;
            }

        }

        async componentDidMount() {
        //async componentWillMount() {
            msalAuth.handleRedirectCallback(() => {
        
                let userAccount = msalAuth.getAccount();
                this.setState({
                    isAuthenticated: true,
                    user: userAccount
                });

            }, (authErr, accountState) => {  // on fail
                //console.log(authErr);

                this.setState({
                    hasError: true,
                    errorMessage: authErr.errorMessage
                });
            });

            if (msalAuth.isCallback(window.location.hash)) {
                this.setState({
                    auth: {
                        renewIframe: true
                    }
                });
                return;
            }

            let userAccount = msalAuth.getAccount();
            if (!userAccount) {
                msalAuth.loginRedirect({});
                return;
            } else {
                this.setState({
                    isAuthenticated: true,
                    user: userAccount
                });
            }
        }

        onSignIn() {
            msalAuth.loginRedirect({});
        }

        onSignOut() {
            msalAuth.logout();
        }

        noKeySet(e) {
            this.setState({
                nokey: e
            });
        }
     
    //}

        render() {
            if (this.state.renewIframe) {
                return <div>hidden renew iframe - not visible</div>;
            }

            if (this.state.isAuthenticated) {
                return <HocComponent auth={this.state} noKeySet={(e) => this.noKeySet(e)} getMail={() => this.getMail()} onSignIn={() => this.onSignIn()} onSignOut={() => this.onSignOut() } {...this.props} />;
            }

            if (this.state.hasError) {
                return <div>{this.state.errorMessage}</div>;
            }

            return <div>Login in progress...</div>;
        }
    };
}
