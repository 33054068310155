import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { FetchKeys } from './components/FetchKeys';
import { GenerateKey } from './components/GenerateKey';
import { HowToUseKey } from './components/HowToUseKey';
import { KeyResult } from './components/KeyResult';
import { Faq } from './components/Faq';
import { withAuth } from './msal/MsalAuthProvider';
import appPackageJson from '../package.json';

/*export default*/
class App0 extends Component {
  static displayName = App0.name;

    componentDidMount() {


        //console.log({ process.env.REACT_APP_TITLE });

        let appV = localStorage.getItem('version');
        if (appV !== appPackageJson.version) {
            if ('caches' in window) {
                caches.keys().then((names) => {
                    names.forEach(name => {
                        caches.delete(name);
                    })
                });
                window.location.reload(true);
            }
            localStorage.clear();
            localStorage.setItem('version', appPackageJson.version);
        }
    }

  render () {
      return (
       <Layout {...this.props}>
            <Route exact path="/" render={(props) => <FetchKeys {...props} {...this.props} />} />
            <Route path="/generate-key" render={(props) => <GenerateKey {...props} {...this.props} />} />
            <Route path="/edit-key/:keyid" render={(props) => <GenerateKey {...props} {...this.props} />} />
            <Route path='/how-to-use-key' component={HowToUseKey} />
            <Route path='/key-result' component={KeyResult} />
            <Route path='/faq' component={Faq} />
      </Layout>
    );
  }
}
export const App = withAuth(App0);