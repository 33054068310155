import React, { Component } from 'react';

export class FolderSelection extends Component {
    static displayName = FolderSelection.name;
   
    constructor(props) {
        super(props);

        this.displayData = [];
        this.timer = null;
        this.state = {
            mail: this.props.mail,
            showdata: this.displayData,
            postVal: "",
            query: null,
            myFolders: null,
            loading: true,
            noResultFound: false
        }
        this.handleSearchChange = this.handleSearchChange.bind(this);

    };

     abortController = new AbortController()

    async componentDidMount() { 
        this.getFolders(this.state.mail,'*','*');
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    async getFolders(m, folderGuid, query) {
        //console.log('api/GetFolders');

        if (query !== '*') {
            //console.log('controller.abort();');
            this.abortController.abort();
        }

        if (this.state.myFolders != null && folderGuid === '*' && query ==='*') {
            //console.log('I already have my initial folder data');
            this.appendData(this.state.myFolders, '*');
            return;
        }

        this.abortController = new AbortController()
     
        //const response = await fetch('api/GetFolders/' + m + '/' + folderGuid + '/' + query,
        //    {
        //        signal: this.abortController.signal,
        //        headers: {
        //            "Api-Key": "aiMTqdx5BoRIr4iRglQvvuvJ-Zugo3Yu1BmJ8ft2nb6-QqjMN5groWN"
        //        }
        //    });
        //const data = await response.json();
        //this.appendData(data, folderGuid);

        //if (this.state.myFolders == null) {
        //    //console.log('This is first time my folderdata is set');
        //    this.setState({
        //        myFolders: data
        //    });
        //}
        //================================


        fetch('api/GetFolders/' + m + '/' + folderGuid + '/' + query,
            {
                signal: this.abortController.signal,
                headers: {
                    "Api-Key": "aiMTqdx5BoRIr4iRglQvvuvJ-Zugo3Yu1BmJ8ft2nb6-QqjMN5groWN"
                 }
            }
        )
            .then(res => res.json())
            .then(data => {
                //console.log(data);
                this.appendData(data, folderGuid);
                  if (this.state.myFolders == null) {
                        //console.log('This is first time my folderdata is set');
                        this.setState({
                            myFolders: data
                        });
                  }
            })
            .catch(err => {
                //console.log(err);
            });
       
   
  
    }

    handleClick = changeEvent => {
        //console.log('handleClick');
    }

    getIndex(guid) {
        return this.displayData.findIndex(folder => folder.key === guid);
    }

    handleFolderChange = changeEvent => {
   
        var hasSubFolder = changeEvent.target.options[changeEvent.target.selectedIndex].dataset.subfolder;

        //after we select something, we do not need to see the select guide
        if (changeEvent.target.options[0].text === "Select folder") {
           // changeEvent.target.options.remove(changeEvent.target.options[0]);
        }

        //update form event on parent component
        this.props.foldersLoaded(changeEvent);

        var id = changeEvent.target.parentElement.id;
        var index = this.getIndex(id);
        this.displayData = this.displayData.slice(0, index + 1);

        if (hasSubFolder === "true") {
            this.getFolders(this.state.mail, changeEvent.target.value, '*');
        }
  
    };

    appendData(folders, folderGuid) {

        //console.log('display result from search');

        if (folders.length > 0) {
             this.displayData.push(
                    <div key={folderGuid} id={folderGuid}>
                        <select onClick={this.handleClick}
                            onChange={this.handleFolderChange}       
                        >  <option>Select folder</option>
                            {folders.map(folder =>
                                folder.hasSubFolders ?
                                    <option key={folder.guid} value={folder.guid} data-subfolder={folder.hasSubFolders}>{folder.name}*</option> :
                                    <option key={folder.guid} value={folder.guid} data-subfolder="false">{folder.name}</option>
                     
                            )}
                        </select>
                    </div>);
        } else {
          
        }

        this.setState({
            showdata: this.displayData,
            postVal: "",
            loading: false,
            noResultFound: folders.length > 0
        });

    }

    prependData() {
        this.displayData.unshift(<div id="display-data"><pre>{this.state.postVal}</pre></div>);
        this.setState({
            showdata: this.displayData,
            postVal: ""
        });
    }

    handleChange(e) {
        let getTextAreaValue = e.target.value;
        this.setState({
            postVal: getTextAreaValue
        });
    }

    handleSearchChange(e) {
        e.preventDefault();
        clearTimeout(this.timer);    
        const query = e.target.value;

        if (this.state.query != null && query.length === 0) {
            this.setState({
                query: null
            });
            this.displayData = [];
            this.getFolders(this.state.mail, '*', '*');
            return;
        }

        const m = this;

        this.timer = setTimeout(function () {
            //console.log('query: ' + query);
            if (query.length > 1) {
                m.setState({
                    query: query
                });
                m.displayData = [];
                m.getFolders(m.state.mail, '*', query);
                m.props.foldersLoaded(null);
            }              
        }, 1000);

    }

    render() {
        let contents = this.state.loading
            ? this.renderWorkingOnIt()
            : this.renderSelection();

        return (
            <div>
                {contents}
            </div>
        );
    }

    renderWorkingOnIt() {
        return (
            <div className="form__field">
                <label htmlFor="folder">Folder</label>
                <div className="page__content__block">
                    <div className="processing-state processing-state--constrain-width"></div>
                </div>
            </div>
        );
    }

    renderSelection() { 
        return (
            <div className={"form__field" + (this.state.noResultFound ? "" : " form-info--error")}>
                <div className="form__field form__field--full-width form__field--inline-label">
                    <label htmlFor="search-inline">Search<span className="form-info__hint">For folders you want to find</span></label>
                    <input type="search" id="search-inline" onChange={this.handleSearchChange} />
                </div>
                <span className="form-info__message">No results found, try another keyword</span>
                <div className="folder-selection">
                    {this.displayData}
                </div>
            </div>
        );
    }



}

