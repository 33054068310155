import React, { Component } from 'react';
import { NavItem } from 'reactstrap';
import { Link, NavLink } from 'react-router-dom';

export class Header extends Component {
    static displayName = Header.name;

    constructor(props) {
        super(props);

        this.state = {
            menuActive: 0,
            isActive: false,
            user: props.auth.user.userName
        }

        this.addActiveClass = this.addActiveClass.bind(this);
        this.outOffFocusClick = this.outOffFocusClick.bind(this);

    }

    async componentDidMount() {
        var m = await this.props.getMail();
        if (m) {
            this.setState({
                givenName: this.props.auth.givenName
            });         
                window.addEventListener("click", this.outOffFocusClick, false);          
        }   
    }

    addActiveClass() {
        this.setState({
            isActive: !this.state.isActive
        })
    }

    outOffFocusClick(e) {
        if (e.target.classList.contains("nav__item--icon") === false) {
            this.setState({
                isActive: false
            })
        }
    }

    render() {
        return (
            <header className="page__header">

                <nav className="nav--has-active-item nav--site-nav theme--dark demo-nav-site">
                    <a className="screenreader-only screenreader-only--show-on-focus" href="#content">Gå til sideindhold</a>
                    <h1 className="nav__home">
                        <a className="nav__home__title" href="/#">Panoptokeys.au.dk</a>
                        </h1>
                    <div className="nav__site">
                        <div className="nav__items" id="nav-items-global">
                            <NavItem>
                                <NavLink exact tag={Link} to="/" activeClassName="nav__item--active" className="nav__item" >Your keys</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} to="/generate-Key" activeClassName="nav__item--active" className="nav__item">Create key</NavLink>
                            </NavItem>
                            <div className="nav__split"></div>
                            <NavItem>
                                <NavLink tag={Link} to={{ pathname: "https://educate.au.dk/it-i-undervisningen/tekniske-vejledninger/panopto/guide/?g=aud" }} target="_blank"
                                    activeClassName="nav__item--active" className="nav__item">Guides</NavLink>
                            </NavItem>
                        </div>

                        <button className="nav__toggle" title="Show full menu" aria-label="Show full menu" aria-haspopup="true" aria-expanded="false" aria-controls="nav-items-global">Menu</button>
                    </div>
                        <div className="nav__utilities">
                            <div className="sub-nav">
                                <button onClick={this.addActiveClass} className="nav__item nav__item--icon nav__item--icon--right"
                                    id="subnav-profile-button"
                                    data-icon=""
                                    aria-expanded="false"
                                aria-label="">{this.state.givenName}</button>
                                <div className={"sub-nav__content theme--normal" + (this.state.isActive ? " active" : "")}
                                    aria-labelledby="subnav-profile-button"
                                    id="subnav-profile-content">
                                    <div className="sub-nav__user">
                                    <h2 className="sub-nav__user-name">{this.props.auth.user.name}</h2>
                                    </div>
                                    <hr />
                                    <a className="sub-nav__item sub-nav__item--icon" href="/#" data-icon="" onClick={this.props.onSignOut}>Log ud</a>
                                </div>
                            </div>
                        </div>       
                </nav>
            </header>
        );
    }
}