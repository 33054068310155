import React, { Component } from 'react';

export class KeyResult extends Component {
    static displayName = KeyResult.name;
    constructor(props) {
        super(props);

        this.state = {
            key: props.location.state.key
        };
    }

    parseLicense(e) {
        return e.slice(0, 3) + " " + e.slice(3, 6) + " " + e.slice(6, 9);
        //var parsedLicens;
        //var p1 = l.slice(0, 3);
        //var p2 = l.slice(3, 6);
        //var p3 = l.slice(6, 9);
        //parsedLicens = p1 + ' ' + p2 + ' ' + p3;
        //return parsedLicens;
    }

    showKeyList(){
        this.props.history.push("/");
    }

    render() {
        //var elements = document.getElementsByClassName('page__content');
        //elements[0].style.paddingTop = "4em";
        
            return (
                <div style={{ paddingTop: "2em" }}>
                    <aside className="aside header-callout header-callout--confirm form__field__shadow-input__content page__content__block--snap-above" aria-labelledby="default-callout">
                        <div className="header-callout__content">
                            <h1 className="header-callout__title" id="default-callout">Your key has been created</h1>
                        </div>
                    </aside>
                    <main className="theme--normal your-key-created" id="content">

                        <h1><strong>Your key has been created</strong></h1>
                     
                        <form noValidate="noValidate">
                            <div className="form__field">
                                <label>Session name</label>
                                <h2>{this.state.key.sessionName}</h2>
                            </div>
                            <div className="form__field">
                                <label>Panopto folder</label>
                                <h2>{this.state.key.folderName}</h2>
                            </div>
                            <div className="form__field">
                                <label>Livestream</label>
                                <h2>{this.state.key.sessionType > 0 ? "On" : "Off"}</h2>
                            </div>
                            <div className="form__field">
                                <label>Key</label>
                                <h2>{this.parseLicense(this.state.key.key)}</h2>
                            </div>
                        </form>
                        <div className="avc-align-centered">
                            <button className="button" data-icon="" onClick={(e) => this.showKeyList()}>Your keys</button>
                        </div>
                    </main>
                </div>
            );
        }
}