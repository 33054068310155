import React, { Component } from 'react';
import { FolderSelection } from './FolderSelection';
import { NavItem } from 'reactstrap';
import { Link, NavLink } from 'react-router-dom';

let head = <div>
    <div className="createKeyPage">
    <div className="text--stamp">Create key</div>
    <h1 className="text--pull"><strong>Create a recording key</strong></h1>
    <p>In selected auditoriums you can record your lectures. To make a recording you will need a key.<br />
            Here you can name the recordings and select the folder where recordings made with this key should be stored.</p>
    </div>
</div>

export class GenerateKey extends Component {
    static displayName = GenerateKey.name;

    constructor(props) {
        super(props);

        //var date = new Date();
        //var dString = date.getFullYear() + '-' + this.addZero((date.getMonth() + 1)) + '-' + this.addZero(date.getDate()) + ' ' + this.addZero(date.getHours()) + ':' + this.addZero(date.getMinutes()) + ':' + this.addZero(date.getSeconds());

        this.folderOpt = React.createRef();

        this.state = {
            keyData: {
                user: '',
                dateTime: '',
                created: '',
                folder: '',
                folderName: '',
                duration: 180,
                sessionType: 0,
                key: '',
                sessionName: '',
                sessionPublishDate: '',
                addPrefixDate: "0",
                lastUsed: '',
                pinned: 0
            },
            optionSessionType: -1,
            optionRecordToFolder: true,
            optionDoLiveStream: false,
            selectedOption2: 0,
            user: props.auth.user.userName,
            mail: null,
            recentFolders: null,
            folders: null,
            acceptkey: "Create",
            loading: true,
            tabSelection: 0,
            isHidden: false,
            snCounter: 0
        };

        this.addKey = this.addKey.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleClose = this.handleClose.bind(this);
        var keyLicensId = this.props.match.params["keyid"];

        //this.handleAddPrefixDateChange = this.handleAddPrefixDateChange.bind(this);
        //this.handleDurationOptionChange = this.handleDurationOptionChange.bind(this);
        //this.handleOption2Change = this.handleOption2Change.bind(this);
        //this.onTabClick = this.onTabClick.bind(this);
        this.handleAddPrefixDateChange = this.handleAddPrefixDateChange.bind(this);
        this.handleDurationOptionChange = this.handleDurationOptionChange.bind(this);
        this.handleOption2Change = this.handleOption2Change.bind(this);
        this.onTabClick = this.onTabClick.bind(this);
        this.handleOptionRecordToFolderChange = this.handleOptionRecordToFolderChange.bind(this);
        this.handleOptionDoLiveStreamChange = this.handleOptionDoLiveStreamChange.bind(this);
        this.updateUserOptions = this.updateUserOptions.bind(this);
        this.onInfoPathClick = this.onInfoPathClick.bind(this);

        if (keyLicensId !== undefined) {
            // if keyLicensId is above, then we are editing a existing keylicens
            fetch('api/Key/' + keyLicensId, {
                method: 'GET',
                headers: {
                    "Api-Key": "aiMTqdx5BoRIr4iRglQvvuvJ-Zugo3Yu1BmJ8ft2nb6-QqjMN5groWN"
                }  /*  fetch('api/key/KeyLicens/' + keyLicensId)*/
            })
                .then(response => response.json())
                .then(data => {
                    const t = (data.sessionPublishDate === null) ? 0 : 1;
                    this.setState({
                        keyData: data,
                        optionSessionType: data.sessionType,
                        selectedOption2: t,
                        acceptkey: "Update",
                        optionRecordToFolder: data.sessionType < 2,
                        optionDoLiveStream: data.sessionType > 0
                    });
                    this.setState({
                        snCounter: data.sessionName.length
                    });
                    //console.log("addPrefixDate: " + data.addPrefixDate);
                    //this.setState({
                    //    keyData: data,
                    //    selectedOption: data.sessionType,
                    //    prefixChecked: data.addPrefixDate,
                    //    duration: data.duration,
                    //    selectedOption2: v,
                    //    acceptkey: 'Update'
                    //});
                });
            //console.log("success fetching one key to edit.");
        }



    }


    addKey(event) {

        if (event.nativeEvent.submitter.innerText === 'Cancel') {
            this.props.history.push("/");
            return;
        }

        if (event.nativeEvent.submitter.innerText === 'Delete') {
            event.preventDefault();
            this.setState({
                isDeleting: true
            });
            return;
        }

        // Handling the submit form event for adding a key.  
        event.preventDefault();
        const data = new FormData(event.target);
        data.append('User', this.state.user);

        //var folderName = this.state.keyData.folderName;
        //data.append('FolderName', folderName);
        //data.append('Folder', this.state.keyData.folder);
        //data.append('AddPrefixDate', this.state.prefixChecked);
        data.append("User", this.state.user);
        data.append("SessionType", this.state.keyData.sessionType);
        data.append("FolderName", this.state.keyData.folderName);
        data.append("Folder", this.state.keyData.folder);
        
        data.append("AddPrefixDate", this.state.keyData.addPrefixDate);
        data.set("Key", this.state.keyData.key);
        data.set("Id", this.state.keyData.id);
        data.set("LastUsed", this.state.keyData.lastUsed);

        //determine whether it is a new key or existing key to be edited
        if (this.state.keyData.key !== '' && this.state.keyData.key !== undefined) {
            //console.log("edit is selected");
            fetch('api/Key', {
                method: 'PUT',
                body: data,
                headers: {
                    "Api-Key": "aiMTqdx5BoRIr4iRglQvvuvJ-Zugo3Yu1BmJ8ft2nb6-QqjMN5groWN"
                }
            }).then((response) => response.json())
                .then((responseJson) => {
                    this.props.history.push("/");
                })
        } else {
            fetch('api/Key', {
                method: 'POST',
                body: data,
                headers: {
                    "Api-Key": "aiMTqdx5BoRIr4iRglQvvuvJ-Zugo3Yu1BmJ8ft2nb6-QqjMN5groWN"
                }
            }).then((response) => response.json())
                .then((responseJson) => {
                    this.props.history.push("/key-result", { key: responseJson });;
                })
        }

    }

    handleDelete(id) {
        //at this point user has already confirmed the deletion of the current key
        fetch('api/Key/' + this.state.keyData.id, {
            method: "DELETE",
            headers: {
                "Api-Key": "aiMTqdx5BoRIr4iRglQvvuvJ-Zugo3Yu1BmJ8ft2nb6-QqjMN5groWN"
            }
        }).then(data => {
            this.props.history.push("/");
        });
    }

    handleClose() {
        this.setState({
            isDeleting: false
        });
    }

    handleCloseInfo() {
        this.setState({
            showPathInfo: false
        });
    }
    handlerecentFolderInputChange = changeEvent => {
        if (changeEvent === null) {
            this.setState({
                keyData: {
                    folder: '',
                    folderName: ''
                }
            });
            return;
        }

        var selFolder = changeEvent.target.options[changeEvent.target.selectedIndex].text;

        const value = changeEvent.target.value;
        this.setState(prevState => ({
            keyData: {
                ...prevState.keyData,
                folder: value,
                folderName: selFolder
            }
        }))
    }
    handleFolderChange = changeEvent => {

        if (changeEvent === null) {
            this.setState({
                keyData: {
                    folder: '',
                    folderName: ''
                }
            });
            return;
        }

        var selFolder = changeEvent.target.options[changeEvent.target.selectedIndex].text;

        const value = changeEvent.target.value;
        this.setState(prevState => ({
            keyData: {
                ...prevState.keyData,
                folder: value,
                folderName: selFolder
            }
        }))

    };

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (target.type === 'radio') {
            //console.log('Target Name: ' + name);
            if (target.checked) {
                this.setState(prevState => ({
                    keyData: {
                        ...prevState.keyData,
                        sessionType: value
                    }
                }))
            }
        } else {

            var tInput = value.replace(/(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g, "");
            //console.log(tInput.length + "/100");
            this.setState({
                snCounter: tInput.length
            });

            this.setState(prevState => ({
                keyData: {
                    ...prevState.keyData,
                    [name]: tInput
                }
            }))
        }

    }

    handleOptionChange(changeEvent) {
        //this.setState({
        //    selectedOption: changeEvent.target.value
        //});
        //console.log(this.state.keyData.folder);
    }

    handleOptionRecordToFolderChange(changeEvent) {
        this.setState({
            optionRecordToFolder: changeEvent.target.checked
        });
    }

    handleOptionDoLiveStreamChange(changeEvent) {
        this.setState({
            optionDoLiveStream: changeEvent.target.checked
        });
    }

    updateUserOptions(changeEvent) {
        var t;
        t = -1;

        if (this.state.optionRecordToFolder === false && this.state.optionDoLiveStream === false) {
            t = -1;
        }

        if (this.state.optionRecordToFolder === true && this.state.optionDoLiveStream === false) {
            t = 0;
        }
        if (this.state.optionRecordToFolder===true && this.state.optionDoLiveStream===true) {
            t = 1;
        }
        if (this.state.optionRecordToFolder===false && this.state.optionDoLiveStream===true) {
            t = 2;
        }
          
        this.setState(prevState => ({
            keyData: {
                ...prevState.keyData,
                sessionType: t
            }
        }));


    }

    handleAddPrefixDateChange(changeEvent) {
        var state = "0";
        if (changeEvent.target.checked) {
            state = "1";
        }
        //state ? "1" : "0";
        this.setState(prevState => ({
            keyData: {
                ...prevState.keyData,
                addPrefixDate: state
            }
        }))
    }

    handleDurationOptionChange(changeEvent) {
        //add auto duration time to recording 180, 360 or 720 minutes
        //when expiring, recording will automatically close
        var t;
        t = Number.parseInt(changeEvent.target.value);
        this.setState(prevState => ({
            keyData: {
                ...prevState.keyData,
                duration: t
            }
        }))
    }

    handleOption2Change(changeEvent) {
        this.setState({
            selectedOption2: Number.parseInt(changeEvent.target.value)//changeEvent.target.value
        });

        if (changeEvent.target.value === '0') {
            this.setState(prevState => ({
                keyData: {
                    ...prevState.keyData,
                    sessionPublishDate: ''
                }
            }))
        }

    }

    onTabClick(event) {
        //onTabClick = (event) => {
        event.preventDefault();
        this.setState({
            isHidden: !this.state.isHidden
        });
    }


    // adds zero to make two digits
    addZero(e) {
        return e < 10 ? e = "0" + e : e;
        //if (n < 10) {
        //    n = "0" + n;
        //    return n;
        //}
        //return n;
    }

    showKeyResult() {

    }


    async componentDidUpdate(e, t) {
        t.optionRecordToFolder !== this.state.optionRecordToFolder && this.updateUserOptions();
        t.optionDoLiveStream !== this.state.optionDoLiveStream && this.updateUserOptions();
    }

    async componentDidMount() {

        //this.getAvaliableDuration();
        this.getRecentFolders();

        var m = await this.props.getMail();
        if (m) {
            this.setState({
                mail: this.props.auth.panoptoUserMail
            });
        }
    }

    render() {
        document.documentElement.style.setProperty('--color-background', "#fefefe");
        let contents = (this.state.loading || this.state.mail === null)
            ? this.renderWorkingOnIt()
            : this.renderData(this.state.recentFolders);
        return (
            <div>
                {contents}
            </div>
        );
    }

    renderWorkingOnIt() {
        return (
            <main className="page__content theme--normal" id="content">
                {head}
                <div className="page__content__block">
                    <div className="processing-state processing-state--constrain-width"></div>
                </div>
            </main>
        );
    }


    onInfoPathClick(event) {
        //onInfoPathClick = (event) => {
        event.preventDefault();
        //this.getFolderPath(this.state.keyData.folder);
        this.setState({
            showPathInfo: true
        });
    }

    toggleHidden() {
        this.setState({
            isHidden: !this.state.isHidden
        })
    }

    renderData(recentFolders) {

        let deleteElement;
        let norecentfolderinfo;
        if (this.state.acceptkey === 'Update') {
            deleteElement = <button className="button--ireversable-action button button--icon--hide-label" data-icon="" value="delete">Delete</button>;
        }

        if (this.state.recentFolders.length === 0) {
            norecentfolderinfo = <p>You have no recent folders</p>;
        }

        return (
            <main className="page__content theme--normal" id="content">

                {/*Deleting*/}
                <div className={"modal-view" + (this.state.isDeleting ? " modal-view--visible" : "")} id="modal1" tabIndex="-1">
                    <div className="modal-view__wrapper theme--normal">
                        <button className="modal-view__close button button--icon button--icon--hide-label"
                            data-icon="" onClick={(e) => this.handleClose()}>Close</button>
                        <div className="modal-view__body">
                            <h2 className="modal-view__header">Delete key?</h2>
                            <div className="modal-view__content">
                                <button className="button" data-icon=""
                                    onClick={(e) => this.handleDelete()}>Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/*Info*/}
                <div className={"modal-view" + (this.state.showPathInfo ? " modal-view--visible" : "")}
                    id="modal2" tabIndex="-1">
                    <div className="modal-view__wrapper theme--normal">
                        <button className="modal-view__close button button--icon button--icon--hide-label"
                            data-icon="" onClick={(e) => this.handleCloseInfo()}>Close</button>
                        <div className="modal-view__body">
                            <h2 className="modal-view__header">Find your course folder name</h2>
                            <div className="modal-view__content">
                                <h2>Go to your course in Brightspace and do the following.</h2>
                                <ol className="list--dense">
                                    <li>Select Panopto</li>
                                    <li>Find the semester and name that the folder has in Panopto \u2013 and select the that folder here in Panoptokeys.</li>
                                    <img alt="" id="imgInfo" src="media/infopath.75c60af0.png"></img>

                                </ol>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="page__content__block">

                    {head}


                    <form className="form" method="post" onSubmit={this.addKey}>
                        {/*<input type="hidden" id="Created" name="created" value={this.state.keyData.created} />*/}
                        {/*<input type="hidden" id="Id" name="id" value={this.state.keyData.id} />*/}
                        {/*<input type="hidden" id="Key" name="key" value={this.state.keyData.key} />*/}
                        {/*<input type="hidden" id="Pinned" name="pinned" value={this.state.keyData.pinned} />*/}

                        <input type="hidden" id="Created" name="created" value={this.state.keyData.created || ''} />
                        <input type="hidden" id="Pinned" name="pinned" value={this.state.keyData.pinned || 0} />
                        <input type="hidden" id="SessionType" name="sessionType" value={this.state.keyData.sessionType || 0} />

                        <fieldset className="fieldset--not-radio-checkbox">
                            <legend>
                                <div className="fieldset__legend-wrapper">Name your recording*</div>
                            </legend>
                            <div className="fieldset__horizontal-wrapper">
                                <div className="form__field form__field--width-relative-12">
                                    <div id="ck" className="row">
                                        <div className="row__item">
                                            <label>You must type in a name for your recording</label>
                                        </div>
                                        <div className="row__item">
                                            <label id="snCounter">{this.state.snCounter}/100</label>
                                        </div>
                                    </div>
                                    <input placeholder="Type in your session name" type="text"
                                        maxLength="100" name="sessionName" id="SessionName"
                                        value={this.state.keyData.sessionName || ''}
                                        onChange={this.handleInputChange}
                                        onKeyPress={(e) => "Enter" === e.key && e.preventDefault()} />
                                    <button id="infoPath" className="tooltip tooltip--icon-left"
                                        title="Show complete path" data-modal="tooltipmodal"
                                        aria-haspopup="true" aria-expanded="false"
                                        onClick={this.onInfoPathClick}>
                                    </button>
                                </div>
                            </div>
                            <div className="form__field form__field--horizontal">
                                <label htmlFor="AddPrefixDate">
                                    <span className="form-info__hint">Include date after session name</span>
                                </label>
                                <label className="switch">
                                    <input type="checkbox" id="AddPrefixDate"
                                        onChange={this.handleAddPrefixDateChange}
                                        checked={this.state.keyData.addPrefixDate === "1"}
                                    />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </fieldset>

                        <fieldset>

                            <div className="form__field form__field--width-relative-4">
                                <legend>
                                    <div className="fieldset__legend-wrapper">Select your recording folder*</div>
                                </legend>
                                <label>
                                    <span className="form-info__hint">Choose between the latest used folders, search for a folder you have acces to or select your "My Folder"</span>
                                </label>

                                <div className="form__field--full-width tabbed-content"
                                    data-initial-tab="1"
                                    data-focus-onload="false">

                                    <div className="nav nav--has-active-item">
                                        <div className="nav__items" role="presentation">
                                            <NavItem>
                                                <NavLink exact tag={Link} to="/"
                                                    onClick={(event) => this.onTabClick(event)}
                                                    className={"nav__item" + (this.state.isHidden === false ? " nav__item--active" : "")}
                                                >Search folders</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink exact tag={Link} to="/"
                                                    onClick={(event) => this.onTabClick(event)}
                                                    className={"nav__item" + (this.state.isHidden === true ? " nav__item--active" : "")}
                                                >Recent folders</NavLink>
                                            </NavItem>
                                           
                                        </div>
                                    </div>
                                    <section className="tabbed-content__section"
                                        style={{ display: (this.state.isHidden === true ? "none" : "") }}
                                        id="sectionSearch" tabIndex="-1" aria-labelledby="tab0_2" role="tabpanel">
                                        <h2 className="text--label-header">...</h2>
                                        <FolderSelection folderGuid="" mail={this.state.mail} foldersLoaded={this.handleFolderChange} />
                                    </section>
                                    <section className="tabbed-content__section"
                                        style={{ display: (this.state.isHidden === false ? "none" : "") }}
                                        id="sectionlast5" tabIndex="-1" aria-labelledby="tab0_1" role="tabpanel">
                                        
                                        <select id="recentFolder"
                                            name="recentFolder"
                                            onChange={this.handlerecentFolderInputChange}>
                                            <option>Select folder</option>
                                            {recentFolders.map(rFolder =>
                                                <option key={rFolder.id} value={rFolder.folder}>{rFolder.folderName}</option>
                                                //working
                                            )}
                                        </select>
                                        {norecentfolderinfo}
                                    </section>
                                    
                                    {/*<section class="tabbed-content__section"*/}
                                    {/*    id="sectionSearch" tabindex="-1" aria-labelledby="tab0_2" role="tabpanel" style="display: none;">*/}
                                    {/*    <h2 class="text--label-header">...</h2>*/}
                                    {/*    <div>*/}
                                    {/*        <div class="form__field">*/}
                                    {/*            <div class="form__field form__field--full-width form__field--inline-label">*/}
                                    {/*                <label for="search-inline">*/}
                                    {/*                    Search<span class="form-info__hint">For folders you want to find</span>*/}
                                    {/*                </label>*/}
                                    {/*                <input type="search" id="search-inline" />*/}
                                    {/*            </div>*/}
                                    {/*            <span class="form-info__message">No results found, try another keyword</span>*/}
                                    {/*            <div class="folder-selection">*/}
                                    {/*                <div id="*">*/}
                                    {/*                    <select>*/}
                                    {/*                        <option>Select folder</option>*/}
                                    {/*                        <option value="7d7b8c79-9619-4c6f-9f57-ad1f007930fb" data-subfolder="true">My Folder*</option>*/}
                                    {/*                        <option value="bca1f940-4f72-4ea1-99bd-ad8a011d5683" data-subfolder="true">#Panopto Support*</option>*/}
                                    {/*                        <option value="c75dba18-c982-4b08-a4f5-ac7800e253f8" data-subfolder="true">Auditorier*</option>*/}
                                    {/*                        <option value="fcb86510-6dc6-4032-abc1-ac1000afd09f" data-subfolder="true">Course Content*</option>*/}
                                    {/*                        <option value="b2ad7272-5db6-4d6f-824c-ad2c00d546da" data-subfolder="true">Department Content*</option>*/}
                                    {/*                        <option value="b32861ec-39f1-4f87-9281-ac6200f20399" data-subfolder="true">LifeSize*</option>*/}
                                    {/*                        <option value="a2674e19-8ad2-41ec-8eb8-ad2800ae5773" data-subfolder="true">Newsroom*</option>*/}
                                    {/*                        <option value="29d0ef77-71bb-40a2-8a55-a90d00db6cdc" data-subfolder="false">Public</option>*/}
                                    {/*                        <option value="ffd175ca-5303-4b9e-88e8-a90d00c3e47c" data-subfolder="true">Remote Recorders*</option>*/}
                                    {/*                        <option value="24e09b11-0333-42ed-9afe-a90500821e4e" data-subfolder="true">Users*</option>*/}
                                    {/*                    </select>*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</section>*/}
                                </div>
                            </div>
                        </fieldset>
                        <fieldset>
                            <div className="form__field">
                                <label htmlFor="">
                                    Select recording options*<span className="form-info__hint">Select if you want to record, live stream or both. If you are recording you can also choose when to make your recording available.</span>
                                </label>
                            </div>
                        </fieldset>
                        <fieldset>
                            <div className="fieldset__horizontal-wrapper align-items-baseline">
                                <div className="form__field">
                                    <label htmlFor="sessionType0">Record to folder</label>
                                    <div className="form__field form__field--horizontal">
                                        <label>Off</label>
                                        <label className="switch">
                                            <input type="checkbox"
                                                checked={this.state.optionRecordToFolder}
                                                onChange={this.handleOptionRecordToFolderChange}
                                            />
                                            <span className="slider round"></span>
                                        </label>
                                        <label>On</label>
                                    </div>
                                    <label>Live stream</label>
                                    <div className="form__field form__field--horizontal">
                                        <label>Off</label>
                                        <label className="switch">
                                            <input type="checkbox"
                                                checked={this.state.optionDoLiveStream}
                                                onChange={this.handleOptionDoLiveStreamChange} />
                                            <span className="slider round"></span>
                                        </label>
                                        <label>On</label>
                                    </div>
                                </div>

                                <div className="form__field" style={{ display: (this.state.optionRecordToFolder === true ? "" : "none") }}>
                                    <label htmlFor="">
                                        Make recording available<span className="form-info__hint">
                                        </span>
                                    </label>
                                    <div className="form__field form__field--horizontal">
                                        <label htmlFor="sessionAvailableFrom1">
                                            Right after recording<span className="form-info__hint">
                                            </span>
                                        </label>
                                        <input type="radio" name="sessionAvailableFrom" value="0"
                                            checked={this.state.selectedOption2 === 0}
                                            onChange={this.handleOption2Change} />
                                    </div>
                                    <div className="form__field form__field--horizontal">
                                        <label htmlFor="sessionAvailableFrom1">
                                            by this date<span className="form-info__hint">
                                            </span>
                                        </label>
                                        <input type="radio" name="sessionAvailableFrom" value="1"
                                            checked={this.state.selectedOption2 === 1}
                                            onChange={this.handleOption2Change} />
                                    </div>


                                    <input type="date" name="sessionPublishDate" id="SessionPublishDate"
                                        style={{ display: (this.state.selectedOption2 === 0 ? "none" : "") }}
                                        value={this.state.keyData.sessionPublishDate || ''}
                                        onChange={this.handleInputChange} />
                                </div>
                            </div>

                            {/*<div className="fieldset__horizontal-wrapper align-items-baseline">*/}

                            {/*    <div className="form__field">*/}
                            {/*        */}{/*<h4 className="text--label-header text--pull">Recording and live streaming</h4>*/}
                            {/*        <label for="">Recording and live streaming<span class="form-info__hint">Choose how you want to record</span></label>*/}
                            {/*        <div className="form__field form__field--horizontal">*/}
                            {/*            <label htmlFor="sessionType0">Record<span className="form-info__hint"></span></label>*/}
                            {/*            <input type="radio"*/}
                            {/*                name="sessionType"*/}
                            {/*                value="0"*/}
                            {/*                checked={(this.state.selectedOption == 0) ? true : false}*/}
                            {/*                onChange={this.handleOptionChange} />*/}
                            {/*        </div>*/}
                            {/*        <div className="form__field form__field--horizontal">*/}
                            {/*            <label htmlFor="sessionType1">Record + Live stream<span className="form-info__hint"></span></label>*/}
                            {/*            <input type="radio"*/}
                            {/*                name="sessionType"*/}
                            {/*                value="1"*/}
                            {/*                checked={(this.state.selectedOption == 1) ? true : false}*/}
                            {/*                onChange={this.handleOptionChange} />*/}
                            {/*        </div>*/}
                            {/*        <div className="form__field form__field--horizontal">*/}
                            {/*            <label htmlFor="sessionType2">Live stream<span className="form-info__hint"></span></label>*/}
                            {/*            <input type="radio"*/}
                            {/*                name="sessionType"*/}
                            {/*                value="2"*/}
                            {/*                checked={(this.state.selectedOption == 2) ? true : false}*/}
                            {/*                onChange={this.handleOptionChange} />*/}
                            {/*        </div>*/}

                            {/*    </div>*/}
                            {/*    */}{/* avc-future-feature*/}
                            {/*    <div class="form__field">*/}
                            {/*        */}{/*<h4 class="text--label-header text--pull">Make recording available</h4>*/}
                            {/*        <label for="">Make recording available<span class="form-info__hint"></span></label>*/}
                            {/*        <div className="form__field form__field--horizontal">*/}
                            {/*            <label htmlFor="sessionAvailableFrom1">Right after recording<span className="form-info__hint"></span></label>*/}
                            {/*            <input type="radio"*/}
                            {/*                name="sessionAvailableFrom"*/}
                            {/*                value="0"*/}
                            {/*                checked={(this.state.selectedOption2 == 0) ? true : false}*/}
                            {/*                onChange={this.handleOption2Change} />*/}
                            {/*        </div>*/}

                            {/*        <div className="form__field form__field--horizontal">*/}
                            {/*            <label htmlFor="sessionAvailableFrom1">by this date<span className="form-info__hint"></span></label>*/}
                            {/*            <input type="radio"*/}
                            {/*                name="sessionAvailableFrom"*/}
                            {/*                value="1"*/}
                            {/*                checked={(this.state.selectedOption2 == 1) ? true : false}*/}
                            {/*                onChange={this.handleOption2Change} />*/}
                            {/*        </div>*/}

                            {/*        <input type="date" disabled={this.state.selectedOption2 == 0}*/}
                            {/*            name="sessionPublishDate"*/}
                            {/*            id="SessionPublishDate"*/}
                            {/*            value={this.state.keyData.sessionPublishDate}*/}
                            {/*            onChange={this.handleInputChange} />*/}
                            {/*    </div>*/}

                            {/*</div>*/}

                        </fieldset>

                        <fieldset>
                            <div className="fieldset__horizontal-wrapper align-items-start">
                                <div className="form__field">
                                    <label htmlFor="">
                                        Max Duration*<span className="form-info__hint">Last select for how long your recording should be, this is a precaution for if you forget to stop the recording yourself.</span>
                                    </label>
                                    <div className="form__field form__field--horizontal">
                                        <label htmlFor="sessionDuration0">
                                            Short(3 hours)<span className="form-info__hint">
                                            </span>
                                        </label>
                                        <input type="radio" id="sessionDuration0" name="duration" value="180"
                                            checked={this.state.keyData.duration === 180}
                                            onChange={this.handleDurationOptionChange}/>
                                    </div>
                                    <div className="form__field form__field--horizontal">
                                        <label htmlFor="sessionDuration1">
                                            Medium(6 hours)<span className="form-info__hint">
                                            </span>
                                        </label>
                                        <input type="radio" id="sessionDuration1" name="duration" value="360"
                                            checked={this.state.keyData.duration === 360}
                                            onChange={this.handleDurationOptionChange}/>
                                    </div>
                                    <div className="form__field form__field--horizontal">
                                        <label htmlFor="sessionDuration2">
                                            Long(12 hours)<span className="form-info__hint">
                                            </span>
                                        </label>
                                        <input type="radio" id="sessionDuration2" name="duration" value="720"
                                            checked={this.state.keyData.duration === 720}
                                            onChange={this.handleDurationOptionChange}/>
                                    </div>
                                </div>
                                {/*<div class="form__field" >*/}
                                {/*    <div class="box theme--background-secondary box--constrain-width">You can always stop your recording early. This is just to make sure that if you forget, the recording will stop automatically.</div>*/}
                                {/*</div>*/}
                            </div>

                            {/*  <div className="avc-form-fullwidth fieldset__horizontal-wrapper align-items-start">*/}
                            {/*<div className="fieldset__horizontal-wrapper align-items-start">*/}
                            {/*    <div className="form__field">*/}
                            {/*        */}{/* <h4 className="text--label-header text--pull">System will automatically stop after:</h4>*/}
                            {/*        <label for="">Session length<span class="form-info__hint">Choose the max length of your session</span></label>*/}
                            {/*        <div className="form__field form__field--horizontal">*/}
                            {/*            <label htmlFor="sessionDuration0">Short(3 hours)<span className="form-info__hint"></span></label>*/}
                            {/*            <input type="radio" id="sessionDuration0"*/}
                            {/*                name="duration"*/}
                            {/*                value="180"*/}
                            {/*                checked={(this.state.duration == 180) ? true : false}*/}
                            {/*                onChange={this.handleDurationOptionChange} />*/}
                            {/*        </div>*/}
                            {/*        <div className="form__field form__field--horizontal">*/}
                            {/*            <label htmlFor="sessionDuration1">Medium(6 hours)<span className="form-info__hint"></span></label>*/}
                            {/*            <input type="radio" id="sessionDuration1"*/}
                            {/*                name="duration"*/}
                            {/*                value="360"*/}
                            {/*                checked={(this.state.duration == 360) ? true : false}*/}
                            {/*                onChange={this.handleDurationOptionChange} />*/}
                            {/*        </div>*/}
                            {/*        <div className="form__field form__field--horizontal">*/}
                            {/*            <label htmlFor="sessionDuration2">Long(12 hours)<span className="form-info__hint"></span></label>*/}
                            {/*            <input type="radio" id="sessionDuration2"*/}
                            {/*                name="duration"*/}
                            {/*                value="720"*/}
                            {/*                checked={(this.state.duration == 720) ? true : false}*/}
                            {/*                onChange={this.handleDurationOptionChange} />*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*    <div className="form__field">*/}
                            {/*        <div className="box theme--background-secondary box--constrain-width">*/}
                            {/*            You can always stop your recording early. This is just to make sure that if you forget, the recording will stop automatically.*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                        </fieldset>

                        <div className="submit button-container">
                       
                            <input className={"button" + (this.state.keyData.folder === '' || this.state.keyData.sessionType < 0 ||  this.state.keyData.sessionName === ''? " visually-disabled" : "")}
                                type="submit" value={this.state.acceptkey}
                                disabled={this.state.keyData.folder === '' || this.state.keyData.sessionType < 0 || this.state.keyData.sessionName === ''} />

                            <button className="button button--text" value="cancel">Cancel</button>
                            {deleteElement}
                        </div>
                    </form>

                </div>
            </main>
        );
    }

    //async getAvaliableDuration() {
    //    const response = await fetch('api/AvaliableDuration');
    //    const data = await response.json();
    //    this.setState({ avaliableDuration: data, loading: false });
    //}

    async getRecentFolders() {
        //const response = await fetch('api/RecentFolders/' + this.state.user);
        const response = await fetch('api/RecentFolders/' + this.state.user, {
            method: 'GET',
            headers: {
                "Api-Key": "aiMTqdx5BoRIr4iRglQvvuvJ-Zugo3Yu1BmJ8ft2nb6-QqjMN5groWN"
            }
        });
        const data = await response.json();
        //console.log("recent folder: " + JSON.stringify(data));
        var uniqueArray = [];
        
        data.forEach(function (itm) {
            var isfound = true;
            uniqueArray.forEach(function (itm2) {
                if (itm.folderName == itm2.folderName) {
                    isfound = false;
                }
            });
            if (isfound) uniqueArray.push(itm);
        });

        //console.log("recent folder: " + JSON.stringify(uniqueArray));
        this.setState({
            recentFolders: uniqueArray,
            loading: false
        });

    }

    foldersLoaded() {
        //console.log('foldersLoaded');
    }

}

export class KeyLicense {
    /*    Id = 0; this is auto incremented at sql db serverside*/
    user = "";
    dateTime = "";
    created = "";
    folder = "";
    folderName = "";
    duration = "0";
    sessionType = "0";
    key = "";
    sessionName;
    addPrefixDate = "0";
}