import React, { Component } from 'react';

export class Faq extends Component {
    static displayName = Faq.name;

        render() {
            return (
                <main className="page__content theme--normal" id="content">
                    <div className="page__content__block">
                        <h1>
                            <strong>Faq</strong> ...</h1>

                        <h2>Why can't I see my course in the "Folder"-dropdown?.</h2>
                        <p>You might need to activate the Panopto-folder in the course to be able to access it. Go to Brightspace and select your course. Create a new content item using "Add existing", select "External Tool Activity" and press "Panopto video". Refresh the "Create key" page again, and the course should appear.</p>
                     
                        <h2>Why do I need to set a "max duration"?</h2>
                        <p>The max duration makes sure that the recording stops after a given time. You can always extend or shorten this time in the auditorium using the panel. See instructions for the panel.</p>
                  
                        <h2>Can I record a video to another user's folder?</h2>
                        <p>If you want to record to a folder in Panopto that you don't have access to (e.g. another user's personal folder), they can share their key, and then you can use this in the auditorium.</p>
                  
                        <h2>Can I setup a key that only live streams but doesn't record?</h2>
                        <p>No, you can't.</p>
                        <p>For more information on how to use Panopto please visit educate.au.dk/en/panopto (LINK: <a href="https://educate.au.dk/en/panopto" target="_blank" rel="noopener noreferrer">https://educate.au.dk/en/panopto</a>)</p>
                        
                    </div>
                </main>
            );
        }
}