import React, { Component } from 'react';

export class HowToUseKey extends Component {
    static displayName = HowToUseKey.name;

        render() {
            return (
                <main className="page__content theme--normal" id="content">
                    <div className="page__content__block">
                        <h1>
                            <strong>How</strong> to use the key</h1>
                        <h2>Step by step... </h2>
                        <ol>
                            <li>.</li>
                            <li>..</li>
                            <li>...</li>
                        </ol>
                    </div>
                </main>
            );
        }
}