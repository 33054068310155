import React, { Component } from 'react';
/*import { Container } from 'reactstrap';*/
import { Header } from './Header';
import { Footer } from './Footer';

export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        return (
            <div className="page">
                <Header  {...this.props} />
                {/*<Container className="">*/}
                {/*        {this.props.children}*/}
                {/*        </Container>*/}
                <main className="page__content page__content--centered">
                    {this.props.children}
                </main>
                <Footer />
            </div> 
        );
    }
}
