import React, { Component } from 'react';

export class Footer extends Component {
    static displayName = Footer.name;

    render() {
        return (
            <footer className="page__footer theme--dark">
                <div className="page__footer__content">
                </div>
                <div className="page__footer__compliance">
                    <ul className="page__footer__compliance__links">
                        <li>
                            <a href="https://www.au.dk/cookiepolitik/">Cookies på Aarhus Universitet</a>
                        </li>
                        <li>
                            <a href="https://www.au.dk/om/profil/privatlivspolitik/">Privatlivspolitik</a>
                        </li>
                    </ul>
                </div>
                <div className="page__footer__artwork">
                    <div className="artwork-peto" aria-hidden="true">
                        <div className="artwork-peto__foreground">
                            <svg viewBox="0 0 600 100">
                                <text x="0" y="100">Adgang</text>
                            </svg>
                        </div>
                        <div className="artwork-peto__background">
                            <svg viewBox="0 0 600 100">
                                <text x="0" y="100">Access</text>
                            </svg>
                        </div>
                    </div>
                </div>
                <object className="page__footer__logo" role="img" type="image/svg+xml" aria-labelledby="page-footer-logo" tabIndex="-1">
                    <p id="page-footer-logo">Aarhus Universitets logo.</p>
                </object>
                <object className="page__footer__seal" role="img" type="image/svg+xml" aria-labelledby="page-footer-seal" tabIndex="-1">
                    <p id="page-footer-seal">Aarhus Universitets segl. Seglet viser et anker omgivet af to delfiner. Universitets motto og latinske navn står skrevet på kanten: Solidum petit in profundis - Universitas Arhusiensis.</p>
                </object>
            </footer>
            //<footer className="page__footer theme--dark">
            //    <div className="page__footer__content">
            //        <div className="row row--align-left">
            //            <div className="row__item row__item--basis-15 old-service-info">
            //                <h1 className="text--default-size text--pull"><strong>Panopto Keys </strong>
            //                    <span className="u-avoid-wrap">is a service offered by:</span>
            //                </h1>
            //                <p>AU IT - Generelle Applikationer,
            //                    <span className="u-avoid-wrap">Aarhus University</span>
            //                    <br />Helsingforsgade 10<br />8200 Aarhus N, Denmark</p>
            //            </div><div className="row__item row__item--basis-15">
            //                <h2 className="text--default-size text--strong text--pull">Support</h2>
            //                <ul className="list--dense ul--no-bullets">
            //                    <li>For support <a href="https://medarbejdere.au.dk/en/administration/it/main-academic-areas/" target="_blank"
            //                    rel="noopener noreferrer">contact your local IT support</a></li>
            //                </ul>
            //            </div>
            //        </div>
            //    </div>
            //    <div className="page__footer__artwork">
            //        <div className="artwork-peto" aria-hidden="true">
            //            <div className="artwork-peto__foreground">
            //            <svg viewBox="0 0 600 200"><text x="0" y="100">Panopto</text>
            //        </svg>
            //        </div>
            //            <div className="artwork-peto__background">
            //            <svg viewBox="0 0 600 200"><text x="0" y="100">Keys</text>
            //        </svg>
            //        </div>
            //    </div></div>
            //    <object className="page__footer__logo" role="img" type="image/svg+xml" tabIndex="-1" aria-labelledby="page-footer-logo">
            //        <p id="page-footer-logo">The logo of Aarhus University.</p>
            //    </object>
            //    <object className="page__footer__seal" role="img" type="image/svg+xml" tabIndex="-1" aria-labelledby="page-footer-seal">
            //        <p id="page-footer-seal">The seal of Aarhus University. The seal depicts an anchor surrounded by two dolphins. The university motto and Latin name are written on the outline: Solidum petit in profundis - Universitas Arhusiensis.</p>
            //    </object>
            //</footer>

        );
    }
}