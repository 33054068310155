import React, { Component } from 'react';
/*import Customcss from './custom.module.css';*/


let headKeylist = <div className="page__content__block">
    <div className="createKeyPage">
        <div className="text--stamp">Your Keys</div>
        <h1 className="text--pull"><strong>Panopto keys</strong></h1>
        <p>This is a list of Panopto keys that you have created. Check that Guides to see how to use a key in an auditorium.</p>
    </div>
</div>

export class FetchKeys extends Component {
    static displayName = FetchKeys.name;

    constructor(props) {
        super(props);

        this.state = {
            keyslicenses: [],
            loading: true,
            user: props.auth.user.userName,
            mail: null,
            NoKeysPageVisible: false,
            NoPanoptoUserFoundVisible: false
        };
        this.handleDelete = this.handleDelete.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.LoadNoKeysPage = this.LoadNoKeysPage.bind(this);

    }

    async componentDidMount() {
        var m = await this.props.getMail();
        if (m) {
            this.setState({
                mail: this.props.auth.panoptoUserMail
            });
            this.listKeyData();
        }
    }

    handleDelete(id) {
        if (!window.confirm("Do you want to delete this keylicens with Id: " + id))
            return;
        else {
            fetch('api/Key/' + id, {
                method: 'delete',
                headers: {
                    "Api-Key": "aiMTqdx5BoRIr4iRglQvvuvJ-Zugo3Yu1BmJ8ft2nb6-QqjMN5groWN"
                }
            }).then(data => {
                this.setState(
                    {
                        keyslicenses: this.state.keyslicenses.filter((key) => {
                            return (key.id !== id);
                        })
                    });
            });
        }
    }

    handleEdit(id) {
        this.props.history.push("/edit-Key/" + id);
    } 

    handleCreate() {
        this.props.noKeySet(false)
        this.props.history.push("/generate-Key");
    } 
    
    handleFavorite(id) {
        let lk = this.state.keyslicenses;
        let i = lk.findIndex(key => key.id === id);
        lk[i].pinned = (lk[i].pinned === 1) ? 0 : 1;

        fetch('api/key/Favorite/' + id + '/' + lk[i].pinned, {
            method: "PUT",
            headers: {
                "Api-Key": "aiMTqdx5BoRIr4iRglQvvuvJ-Zugo3Yu1BmJ8ft2nb6-QqjMN5groWN"
            }
        })
            .then(response => response.json())
            .then(data => {

            });
  
        lk.sort(function (a, b) {
            return b.pinned - a.pinned;
        });
        //lk.sort((a, b) => (a.pinned - b.pinned || a.id.localeCompare(b.id)));

        setTimeout(() => {
            this.setState(
                {
                    keyslicenses: lk
                });
        }, 200);

    }

    dummie() {
        //console.log('');
    }

    parseLicense(e) {
        return e.slice(0, 3) + " " + e.slice(3, 6) + " " + e.slice(6, 9)
        //var parsedLicens;
        //var p1 = l.slice(0, 3);
        //var p2 = l.slice(3, 6);
        //var p3 = l.slice(6, 9);
        //parsedLicens = p1 + ' ' + p2 + ' ' + p3;
        //return parsedLicens;
    }

    parseRecordingInfo(key) {
        return key.folderName;
    }

    renderKeylistTable(keys) {

        keys.sort(function (a, b) {
            return b.pinned - a.pinned;
        });
        let keylist;

        if (keys.length > 0) {
            keylist = <ul className="settings-list links--plain">
                {keys.map((keylicens, index) =>
                    <li key={index} className="settings-list__item">
                        <div className="settings-list__item__values">
                            <div className="avc settings-list__item__value">
                                <div>Title</div>
                                <div className="avc-key-value"><strong>{keylicens.sessionName}</strong></div>
                            </div>

                            <div className="avc settings-list__item__value">
                                <div>Key</div>
                                <div className="avc-key-value key"><strong>{this.parseLicense(keylicens.key)}</strong></div>
                            </div>
                            <div className="avc settings-list__item__value">
                                <div>Saved in</div>
                                <div className="avc-key-value"><strong>{this.parseRecordingInfo(keylicens)}</strong></div>
                            </div>
                        </div>
                        <div className="avc-toggle toggle-button toggle-button--favorite toggle-button--small" data-icon="">
                            <input type="checkbox" id="demo-small-button"
                                title="Save as favorite"
                                onClick={(e) => this.handleFavorite(keylicens.id, e)}
                                checked={(keylicens.pinned === 1) ? true : false}
                                onChange={this.dummie}
                            />
                            <label htmlFor="demo-small-button">Save as favorite</label>
                        </div>
                        <button className="settings-list__item__action button button--small"
                            onClick={(e) => this.handleEdit(keylicens.id, e)}>Edit</button>
                    </li>
                )}
            </ul>
        } else {
            this.LoadNoKeysPage();
        }

        return (
            <main className="page__content theme--normal" id="content">    
                {headKeylist}

                <div className="page__content__block">
                    {keylist}
                </div>
            </main>


        );
    }

    openGuides() {
        const url = 'https://educate.au.dk/it-i-undervisningen/tekniske-vejledninger/panopto/guide/?g=aud#c455370';
        window.open(url, '_blank');
    }

    render() {

        if (this.state.NoKeysPageVisible) {
            document.documentElement.style.setProperty('--color-background', "#002241");
         
            return (
                <div className="nokeys">
                    <div className="welcome"><h1 className= "welcomeh1">Welcome to Panopto Keys</h1></div>
                    <div className="button-container button-container--fixed-width button-container--vertical button-container--centered links--plain">
                        <button className="button" data-icon="" onClick={(e) => this.handleCreate()}>Create key</button>
                        <button onClick={this.openGuides} className="button button--icon button--dimmed" data-icon="">Guides</button>
                    </div>
                </div>
                /*
                 <div className="page__content__block theme--dark" className ={Customcss.nokeys}>
                    <div className={Customcss.welcome}><h1 className={Customcss.welcomeh1}>Welcome to Panopto Keys</h1></div>
                    <div className="button-container button-container--fixed-width button-container--vertical button-container--centered links--plain">
                        <button className="button" data-icon="" onClick={(e) => this.handleCreate()}>Create key</button>
                        <button onClick={this.openGuides} className="button button--icon button--dimmed" data-icon="">Guides</button>
                    </div>
                </div>
                 */
                //<div className="avc-nokeys">
                //    <div className="avc-align-centered">
                //        <h1>Welcome to Panopto Keys</h1>
                //    </div>
                //    <div className="avc-align-centered">
                //        <button className="button" data-icon="" onClick={(e) => this.handleCreate()}>Create key</button>
                //    </div>
                //    <div className="avc-align-centered">
                //        <button onClick={this.openGuides} className="button button--icon button--dimmed" data-icon="">Guides</button>
                //    </div>
                //</div>
            )
        }

        if (this.state.NoPanoptoUserFoundVisible) {
            return (
                <div className="notification notification--attention">
                    <div className="notification__content">
                        <p>You don't have a Panopto user and therefore you cannot use Panoptokeys.</p>
                        <p>Please go to au.cloud.panopto.eu and login, a user will then automatically be created for you.</p>
                        <p>Refresh this page when you have done so, then you can use panoptokeys.</p>
                    </div>
                </div>

            )
        }

        let contents = this.state.loading
            ? this.renderWorkingOnIt()
            : this.renderKeylistTable(this.state.keyslicenses);

        return (
            <div>
                {contents}
            </div>
        );
    }

    renderWorkingOnIt() {
        return (
            <main className="page__content theme--normal" id="content">
                {headKeylist}
                <div className="page__content__block">
                    <div className="processing-state processing-state--constrain-width"></div>
                </div>
            </main>
        );
    }

    async listKeyData() {
        //const response = await fetch('api/Key/' + this.state.user + '/' + this.state.mail);
        fetch('api/Key/' + this.state.user + '/' + this.state.mail, {
            method: "GET",
            headers: {
                "Api-Key": "aiMTqdx5BoRIr4iRglQvvuvJ-Zugo3Yu1BmJ8ft2nb6-QqjMN5groWN"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({ keyslicenses: data, loading: false });
            });
        //const data = await response.json();
        //this.setState({ keyslicenses: data, loading: false });
    }

    LoadNoKeysPage() {
        this.setState({ NoKeysPageVisible: true });
    }

    NoPanoptoUserFoundPage() {
        this.setState({ NoPanoptoUserFoundVisible: true });
    }
}